const { I18nextProvider } = require('react-i18next')
const { initLocale, i18n } = require('./src/localization/i18n.mjs')
const { SwanConfiguration } = require('./SwanConfiguration')
const React = require('react')

exports.onClientEntry = () => {
  if (typeof IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
  }
}

exports.wrapPageElement = ({ element, props }) => {
  const { locale, tenant, translation } = props.pageContext
  initLocale(locale, { translation })
  return (
    <I18nextProvider i18n={i18n}>
      <SwanConfiguration tenant={tenant} locale={locale}>
        {element}
      </SwanConfiguration>
    </I18nextProvider>
  )
}
