import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

function initLocale(locale, translations, fallbackLanguage) {
  return i18n.use(initReactI18next).init({
    lng: locale,
    fallbackLng: fallbackLanguage,
    debug: false,
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default.
    },
    resources: { [locale]: translations },
  })
}

export { i18n, initLocale }
