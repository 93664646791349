import * as React from 'react'
import { SwanProvider, useBrowserClasses } from '@vp/swan'

export const SwanConfiguration = ({ children, tenant, locale }) => {
  useBrowserClasses(true) // Passing true, auto adds browser class to head, using Vanially JS QuerySelector
  return (
    <SwanProvider swanTenant={tenant} swanLocale={locale}>
      {children}
    </SwanProvider>
  )
}
